/* DASHBOARD PAGE */

.dashboard-page {
    background-color: rgb(11, 11, 96);
    height: 92vh;
    display: flex;
    padding-bottom: 2px;
}

.dashboard-page-left {
    width: 18%;
    height: 100%;
    /* overflow: auto; */
}

.dashboard-page-right {
    width: 83%;
    height: 100%;
    overflow: auto;
    padding: 10px;
    background-color: lightgray;
}

::-webkit-scrollbar {
    display: none;
}

/* DASHBOARD MENU */

.dashboard-menu-page {
    width: 100%;
    height: 100%;
    padding: 30px 0;
    background-color: rgb(11, 11, 96);
    overflow: auto;
}

.dashboard-menu-page h2 {
    font-size: 30px;
    color: rgb(224, 213, 8);
    text-align: center;
    margin-bottom: 20px;
}

.dashboard-menu-page img {
    height: 30px;
}

.dashboard-menu-page a {
    display: flex;
    align-items: center;
    color: rgb(235, 229, 229);
    text-decoration: none;
    font-size: 15px;
    margin: 2px 0;
    padding: 10px 15px;
    text-transform: uppercase;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 700;

}

.dashboard-menu-page a:hover {
    background-color: gold;
    color: rgb(204, 17, 17);
}

.dashboard-menu-page a.active {
    background-color: gold;
    height: auto;
    color: rgb(204, 17, 17);
    /* text-transform: uppercase; */
}

.popup {
    position: absolute;
    top: 0;
    left: 0;
    background-color: gray;
    width: 100%;
    min-height: 100%;
}

.popup-box {
    padding: 10px;
    background: white;
    width: 400px;
    margin: 30px auto;
    border-radius: 4px;
}

.action-btn {
    display: flex;
    justify-content: space-evenly;
    padding-top: 20px;
}

@media only screen and (max-width:1300px) {
    .dashboard-page-left {
        width: 270px;
    }
}

@media only screen and (max-width:700px) {
    .dashboard-page-left {
        width: 330px;
    }
}