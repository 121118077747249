.student-record-section {
    width: 80%;
    margin: 20px auto;
}

.students-card {
    width: 200px;
    box-sizing: border-box;
    border-radius: 6px;
    border: 3.5px solid rgb(102, 100, 100);
    padding-bottom: 20px;
    z-index: 2000;
    background-color: white;
}

.students-card-img {
    background-color: rgb(8, 75, 130);
    padding: 20px;
    display: flex;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.students-card-img img {
    width: 170px;
    height: 170px;
    border-radius: 50%;
    border: 3.5px solid white;
}

.students-card-text p {
    text-align: center;
    color: darkblue;
    font-size: 20px;
    font-weight: 650;

}

.slick-slide>div {
    margin: 10px 10px;
}