.result_page {
    min-height: 80vh;
    padding: 20px;
    background-color: lightgray;
}

.find_result {
    border: 2px solid white;
    width: 96%;
    max-width: 350px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 6px;

    input {
        width: 100%;
        padding: 7px 20px;
        border-radius: 5px;
        margin:2px 0 8px 0;
        text-transform: uppercase;
        font-size: 15px;
    }
    label{
        text-transform: uppercase;
        margin: 0 10px;
        font-weight: 700;
        color: rgb(29, 29, 146);
    }
}