/*  SEARCHED STUDENTS */
.student-details-comp {

    h2 {
        text-align: center;
        color: white;
        background-color: gray;
        padding: 6px;
        font-weight: 600;
    }

    p {
        padding: 3px 0;
        font-weight: 620;
        font-size: 15px;
    }

    table {
        margin: 20px auto;

        th,
        td {
            padding: 7px 20px;
            text-align: center;
            color: blue;
            font-weight: 600;
            font-size: 16px;
        }

        th {
            font-weight: 650;
            color: black;
            padding: 15px 20px;
        }
    }

}


/* ADD NEW COURSE */
.add-new-course-comp {
    width: 100%;
    position: absolute;
    top: 10px;

    form {
        width: 500px;
        background-color: rgb(244, 241, 241);
        padding: 30px;
        border-radius: 7px;
        margin: 0 auto;
    }
}

.add-new-course-comp h2 {
    text-align: center;
    padding-bottom: 20px;
    font-size: 30px;
}

/* ADD NEW TEACHER */
.new-teacher-comp {
    width: 100%;
    position: absolute;
    top: 10px;
    padding: 20px 0;

    h2 {
        text-align: center;
        padding-bottom: 20px;
        font-size: 34px;
    }

    form {
        width: 700px;
        padding: 20px 30px;
        margin: 0 auto;
        border-radius: 5px;
        border: 2px solid rgb(233, 236, 237);
    }
}


/* STUDENTS ID CARD */

.student-id-card-comp {
    width: 600px;
    position: relative;
    margin: 0 auto;
    padding: 5px;

    .card-img img {
        width: 100%;
        border: 5px solid rgb(185, 185, 180);
    }

    .student-img img {
        position: absolute;
        height: 145px;
        width: 145px;
        top: 36%;
        left: 6.2%;
        border-radius: 50%;

    }

    .student-details,
    .student_id {
        position: absolute;
        top: 49%;
        left: 53%;
        text-transform: uppercase;
        color: rgb(7, 68, 103);
    }

    p {
        font-weight: 650;
        font-size: 18px;
        padding: 1.5px;
    }

    .student_id {
        top: 84%;
        left: 6%;
        color: white;
        font-size: 20px;
        font-weight: 600;
    }
}


/* USER PROFILE COMP */
.user-profile-comp {
    position: relative;

    .cover-img {
        width: 100%;
        height: 250px;
    }

    .profile-img {
        height: 200px;
        width: 200px;
        border-radius: 50%;
        position: absolute;
        top: 115px;
        right: 10%;
        border: 7px solid rgb(255, 255, 255);
    }

    .profile-details {
        text-transform: capitalize;

        h2 {
            background-color: gray;
            width: 80%;
            padding: 6px 0;
            font-size: 30px;
            text-align: center;
            margin-bottom: 20px;
            color: rgb(210, 207, 207);
        }

        p {
            padding: 4px;
            font-size: 18px;
            font-weight: 600;
        }

    }
}


/* ENROLL STUDENT */
.enroll-student-comp {

    form {
        background-color: white;
        width: 500px;
        padding: 30px;
        margin: 40px auto;
        border-radius: 5px;
        text-align: center;

        table {
            margin: 0px auto;
            margin-top: 20px;
        }

        td {
            padding: 10px;
        }

        th {
            font-weight: 600;
            padding: 14px 10px;
        }
    }
}

/* PROFILE UPDATE COMP */
.update-profile-comp {
    width: 750px;
    margin: 20px auto;
    background-color: lightgray;
    padding: 30px;
    border-radius: 6px;
}

/* MARKSHEET COMP */
.marksheet-comp {
    border: 25px solid rgb(145, 48, 4);
    padding-bottom: 20px;
    margin: 20px;
    max-width: 800px;
    background-color: lightgray;

    h1 {
        font-size: 50px;
        font-weight: 700;
        color: blue;
        text-transform: uppercase;
        text-align: center;
    }

    .center-address {
        text-align: center;
    }

    p {
        font-weight: 600;
        font-size: 20px;
        text-transform: uppercase;
        margin: 0;
    }

    .student-personal-details {
        display: flex;
        text-transform: uppercase;
        padding: 10px 34px;
        font-size: 18px;

        div {
            font-weight: 700;
            color: rgb(20, 20, 145);
        }
    }

    .mark-details {
        padding: 0 20px;

        h2 {
            text-align: center;
            background-color: gray;
            padding: 6px;
            color: white;
            font-weight: 600;
            text-transform: uppercase;
        }

        table {
            margin: 10px 0 20px;
            text-align: center;
        }

        td,
        th {
            padding: 5px 10px;
        }

        th {
            font-weight: 700;
            padding: 8px 10px;
            text-transform: uppercase;
        }
    }

    .course-details {
        display: flex;

        div {
            font-size: 18px;
            font-weight: 600;
            text-transform: capitalize;
        }

    }

    .center-director {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        margin-top: 40px;
        align-items: end;

        div {
            font-weight: 700;
            text-align: right;
        }

    }
}


/* CERTIFICATE */

.certificate-details {
    font-weight: 650;
    color: rgb(13, 13, 131);
    padding: 0 20px;
    text-align: justify;
    font-size: 18px;

    span {
        font-weight: 750;
        color: rgb(7, 29, 228);
        text-transform: uppercase;
        margin: 0 6px;
        /* text-decoration: underline; */
    }
}

.cert-no {
    padding: 10px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    color: rgb(129, 42, 8);

    div {
        font-weight: 700;
        font-size: 20px;
    }
}