.verification_page {
    padding-bottom: 30px;
    min-height: 80vh;
    background-color: lightgray;

    .result_found {
        max-width: 800px;
        margin: 10px auto;
        border: 2px solid rgb(0, 0, 0);
        padding: 20px;
        border-radius: 6px;
    }
    .student-personal-details {
        display: flex;
        text-transform: uppercase;
        padding: 10px 34px;
        font-size: 18px;

        div {
            font-weight: 700;
            color: rgb(20, 20, 145);
        }
    }
    .mark-details {
        padding: 0 20px;

        h2 {
            text-align: center;
            background-color: gray;
            padding: 6px;
            color: white;
            font-weight: 600;
            text-transform: uppercase;
        }

        table {
            margin: 10px 0 20px;
            text-align: center;
        }

        td,
        th {
            padding: 5px 10px;
        }

        th {
            font-weight: 700;
            padding: 8px 10px;
            text-transform: uppercase;
        }
    }
    .course-details {
        display: flex;

        div {
            font-size: 18px;
            font-weight: 600;
            text-transform: capitalize;
        }

    }
}