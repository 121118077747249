* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
}

input[type="radio"] {
  transform: scale(1.7);
  width: 30px;
  align-items: center;
}