/* CSS FOR NEW ADMISSION */

.new-admission-page {
    padding: 30px 10px;
}

.new-admission-page h2,
.dash-heading {
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 20px;
    font-size: 40px;
    font-weight: 600;
}

.new-admission-page form {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 5px;
    border: 2px solid rgb(244, 244, 244);
}

/* COMMON */
form div input,
select {
    width: 100%;
    border: 2px solid rgb(173, 166, 166);
    padding: 8px 20px;
    margin: 7px 0;
    outline: none;
    border-radius: 6px;
    font-size: 17px;

}

.new-admission-page form input[type="text"] {
    text-transform: capitalize;
}

form div label {
    display: block;
    width: 100%;
    margin: 0 10px;
    padding-top: 10px;
    font-size: 18px;
    font-weight: 600;
}

/* COMMON */


/* STUDENTS DETAILS */
.student-details-page {
    padding: 30px 0;
}

.search-student-id {
    text-align: center;
}

.search-student-id input {
    width: 400px;
    padding: 9px 20px;
    border: 2px solid gray;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 10px;
}

.searched-student-details {
    /* border: 2px solid darkblue; */
    margin: 0 auto;
    margin-top: 30px;
    max-width: 900px;
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
}



/* COURSES DETAILS */

.course-details-page {
    width: 100%;
    position: relative;
}

.all-courses-details {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.course-card {
    width: 300px;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 5px;
    padding-bottom: 10px;
    margin:20px 10px;
    text-align: center;

    img {
        width: 100%;
        height: 200px;
        border-radius: 5px;
    }

    .card-contents {
        padding: 5px;

        span,
        h4 {
            font-weight: 700;
        }

    }
}


/* SINGLE COURSE PAGE */

.single-course-details-page {
    width: 100%;
    margin: 0px auto;
    position: relative;

    img {
        width: 100%;
        height: 350px;
    }

    .course-details {
        padding: 10px 30px;

        h1 {
            color: darkblue;
            font-weight: 700;
        }

        h2 {
            color: brown;
            padding: 3px;
            font-size: 21px;
            font-weight: 600;
        }
    }

    .enrolled-students {
        padding: 20px;

        h2 {
            text-align: center;
            padding-bottom: 20px;
        }

        table {
            margin: 0 auto;
        }

        th,
        td {
            width: 300px;
            text-align: center;
            padding: 13px 10px;
            font-size: 20px;
        }
    }

}



/* Teachers  PAGE */

.teachers-page {
    .teacher-details {
        table {
            margin: 10px auto;

            th,
            td {
                font-size: 20px;
                text-align: center;
            }

            th {
                padding: 20px 10px;
                font-weight: 600;
            }

            td {
                padding: 4px 10px;
            }
        }
    }
}

/* CHANGE PASSWORD PAGE */
.password-change-page {
    width: 100%;
    max-width: 400px;
    margin: 40px auto;
    border: 2px solid white;
    padding: 20px;
    border-radius: 7px;

    h2 {
        font-size: 28px;
        text-align: center;
        text-transform: uppercase;
        padding: 14px 0;
        font-weight: 700;
    }
}

/* REPORT CARD PAGE */
.gen-report-card-page {
    h2 {
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        background-color: gray;
        padding: 4px;
        color: white;
    }

    table {
        margin: 20px auto;
        width: 100%;
        text-align: center;
        max-width: 900px;

        td,
        th {
            padding: 7px;

        }

        th {
            padding: 12px 10px;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 19px;
        }

        input {
            padding: 6px 10px;
            width: 100%;
            border-radius: 4px;
            text-transform: uppercase;
            font-size: 15px;
            text-align: center;
        }
    }

    .personal-details {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;

        input {
            width: 30%;
            padding: 6px 12px;
            margin: 7px 3px;
            border-radius: 6px;
            text-transform: uppercase;
            font-size: 15px;
            text-align: center;
        }
    }

}