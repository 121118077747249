.h-carousel {
    width: 90%;
    margin: 0 auto;
    z-index: -2000;
}

.h-carousel-img img {
    width: 100%;
    height: 60vh;
    z-index: -2000;
}

@media screen and (max-width : 600px) {
    .h-carousel-img img {
        width: 100%;
        height: 40vh;
    }
}