.home-page {
    margin: 0;
    padding: 0;
    background-color: #ebe7e7;
    z-index: -100;
}

.h-students-sec {
    text-align: center;
    padding: 40px 0px;
}

.home-bg-img {
    width: 100%;
    height: 70vh;
}

.home-content {
    width: 100%;
    max-width: 1300px;
    margin: 20px auto;
    padding: 0 20px;

    h1 {
        /* background-color: gray; */
        color: rgb(0, 0, 0);
        padding: 10px;
        /* text-align: center; */
        text-transform: uppercase;
        font-weight: 700;
    }

    p {
        padding: 6px 50px;
        font-size: 21px;
        color: rgb(65, 62, 62);
        text-align: justify;
        font-weight: 600;

        span {
            font-weight: 800;

        }
    }
}

@media screen and (max-width:500px) {
    .home-bg-img{
        height: 50vh;
    }
    .home-content{
        padding: 5px;
        text-align: center;
        p{
            padding: 6px 25px;
        }

    }
}