.acc-header-heading {
    background-color: rgb(11, 11, 96);
    height: 100px;
    font-size: 60px;
    padding: 5px;
    text-align: center;
    background: linear-gradient(to bottom, #f32170, #ff6b08, #cf23cf, #eedd44);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

}

header .acc-header {
    background-color: rgb(11, 11, 96);
    font-weight: 700;

}

.acc-header-bottom {
    box-sizing: border-box;
    color: red;
    width: 95vw;
    white-space: nowrap;
    margin: 0px 0px;
    height: 25px;
    font-size: 16px;
    font-weight: 600;
    overflow: hidden;
    overflow-x: hidden;
    animation: textMovingAnimation 12s linear alternate-reverse infinite;
    animation-delay: 4s;
}

@keyframes textMovingAnimation {
    0% {
        padding: 0px 47vw;
    }

    10% {
        padding: 0px 43vw;
    }

    20% {
        padding: 0px 39vw;
    }

    30% {
        padding: 0px 35vw;
    }

    40% {
        padding: 0px 31vw;
    }

    50% {
        padding: 0px 27vw;
    }

    60% {
        padding: 0px 23vw;
    }

    70% {
        padding: 0px 19vw;
    }

    80% {
        padding: 0px 15vw;
    }

    90% {
        padding: 0px 11vw;
    }

    100% {
        padding: 0px 7vw;
    }
}

/* header{
    position: sticky;
    top: 0;
} */

.acc-navbar {
    background-color: rgb(8, 75, 130);
    height: 50px;
    list-style: none;
    border-top: 2px solid gray;
    border-bottom: 2px solid gray;
    margin: 0;
    padding: 0px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 2s linear;
    position: sticky;
    z-index: 500000;

}

.acc-navbar-left {
    display: flex;
    /* border: 2px solid red; */
}

.acc-navbar-right {
    display: flex;
    align-content: center;
    /* border: 2px solid red; */
    transition: all 2s linear;
    margin-right: 10px;
}

.acc-navbar li {
    height: 50px;
    position: relative;
    box-sizing: border-box;
    /* border-right: 2px solid gray; */
}

.acc-navbar li a,
.nav-verification {
    display: block;
    color: white;
    text-decoration: none;
    font-size: 17px;
    padding: 13px 20px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    z-index: 9000000;
}

.acc-navbar a:hover {
    background-color: rgb(132, 28, 28);
    height: 48px;
    color: white;
}

.acc-navbar a.active {
    background-color: rgb(5, 5, 101);
    height: 48px;
    color: white;
}

.nav-verification {
    color: white;
}

.nav-verification+div {
    background-color: rgb(255, 255, 255);
    border-radius: 6px;
    position: absolute;
    top: 40px;
    left: -20px;
    z-index: 30000;

    a {
        width: 267px;
        color: black;
    }
}

.side-drawer {
    display: none;
}

@media only screen and (max-width:1000px) {
    .acc-header-heading {
        font-size: 53px;
    }
}



@media only screen and (max-width:800px) {
    .acc-header-heading {
        font-size: 45px;
        height: 110px;
        padding: 20px 0px;
    }
}

@media only screen and (max-width:600px) {
    .acc-header-heading {
        font-size: 38px;
        height: 90px;
    }
}

@media only screen and (max-width:490px) {
    .acc-header-heading {
        font-size: 32px;
        height: 90px;
        padding: 20px 6px;
    }
}

@media only screen and (max-width:440px) {
    .acc-header-heading {
        font-size: 27px;
    }
}

@media only screen and (max-width:380px) {
    .acc-header-heading {
        font-size: 22px;
        height: 70px;
    }
}


/* for nav bar */
@media only screen and (max-width:800px) {

    .acc-navbar .acc-navbar-right {
        display: none;
    }

    .side-drawer {
        display: block;
    }
}