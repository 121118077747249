.error-page{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin: 0;
    padding: 30px;
    padding-bottom:20vh;
    background: lightgray;
    flex-direction: column;
}
.error-page-heading{
    font-size: 300px;
    font-weight:550 ;
    color:white;
    background: linear-gradient(to bottom, darkblue,red);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
.error-page-404{
    font-size: 30px;
    font-weight: 570;
}
.error-page-btn{
    background-color:blue;
    color: white;
    font-weight: 600;
    padding: 15px 34px;
    border: none;
    cursor:pointer;
    border-radius: 30px;
    font-size: 18px;
}

@media only screen  and (max-width:1000px){
    .error-page-heading{
        font-size: 250px;
    }
}

@media only screen  and (max-width:750px){
    .error-page-heading{
        font-size: 200px;
    }
}
@media only screen  and (max-width:550px){
    .error-page-heading{
        font-size: 170px;
    }
    .error-page-404{
        font-size: 25px;
    }
}
@media only screen  and (max-width:450px){
    .error-page-heading{
        font-size: 120px;
    }
    .error-page-404{
        font-size: 20px;
    }
}