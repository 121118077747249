.about_us {
    margin-top: 0;
    background-color: rgb(3, 3, 54);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

.about_us_heading {
    /* background-color: rgb(126, 38, 38); */
    color: white;
    text-align: center;
    padding: 10px;
    margin-top: 20px;
    font-size: 30px;
    text-transform: uppercase;
}

.faculty {
    height: 340px;
    width: 260px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    margin: 10px;
    text-align: center;
    padding: 20px;
}

.faculty_img {
    height: 180px;
    width: 180px;
    border-radius: 50%;
    border: 4px solid rgb(6, 6, 59);
}

.faculty_qualification {
    margin: 6px;
    margin-bottom: 16px;
}


.faculty_name {
    margin: 4px;
    margin-top: 10px;
}

.contact_faculty a {
    color: white;
    font-size: 16px;
    text-decoration: none;
    background-color: rgb(3, 3, 88);
    padding: 6px 20px;
    margin: 7px;
    border-radius: 10px;
    font-weight: 600;
}

.contact_faculty a:hover {
    background-color: brown;
}

.contact_faculty a:active {
    background-color: green;
}

@media only screen and (max-width:615px) {
    .our-team {
        flex-direction: column;
    }
}

@media only screen and (max-width:530px) {
    .about_us_heading {
        font-size: 20px;
        width: 80%;
    }
}

@media only screen and (max-width:400px) {
    .about_us_heading {
        font-size: 18px;
        width: 85%;
    }
}